export const PrivacyPolicy = () => {
  return (
    <div style={{ width: '60%', margin: 'auto', paddingTop: '100px' }}>
      <h1>Privacy Policy</h1>
      <p>
        <strong>Effective Date:</strong> 2024/10/01
      </p>
      <p>
        <strong>Last Updated:</strong> 2024/10/01
      </p>
      <p>
        Velocity Manager ("we," "our," or "us") is committed to protecting your
        privacy. This Privacy Policy explains how we collect, use, and protect
        your information when you use our scheduling application ("App") and
        related services ("Services"). By using the Services, you agree to the
        practices described in this Privacy Policy.
      </p>

      <h2>1. Information We Collect</h2>
      <p>We collect the following types of information:</p>
      <ul>
        <li>
          <strong>Personal Information:</strong> Includes your name, email
          address, phone number, payment details, and other information you
          provide during registration or use of the Services.
        </li>
        <li>
          <strong>Business Information:</strong> Includes employee, client, and
          scheduling data you input into the App.
        </li>
        <li>
          <strong>Usage Data:</strong> Includes information about how you
          interact with the App, such as device information, IP address, and
          cookies.
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Provide, maintain, and improve the Services.</li>
        <li>Process payments and manage subscriptions.</li>
        <li>
          Communicate with you regarding updates, support, and promotional
          content.
        </li>
        <li>Ensure security and prevent fraud.</li>
        <li>Comply with legal obligations.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>We may share your information with:</p>
      <ul>
        <li>
          <strong>Service Providers:</strong> Third-party providers who assist
          in operating the Services, such as payment processors and hosting
          providers.
        </li>
        <li>
          <strong>Legal Authorities:</strong> When required to comply with
          applicable laws, regulations, or legal proceedings.
        </li>
        <li>
          <strong>Business Transfers:</strong> In the event of a merger,
          acquisition, or sale of assets, your information may be transferred to
          the new entity.
        </li>
      </ul>

      <h2>4. Cookies and Tracking Technologies</h2>
      <p>
        We use cookies and similar technologies to improve your experience. You
        can manage cookie preferences through your browser settings.
      </p>

      <h2>5. Data Security</h2>
      <p>
        We implement industry-standard measures to protect your information.
        However, no system is completely secure, and we cannot guarantee
        absolute security.
      </p>

      <h2>6. Data Retention</h2>
      <p>
        We retain your information for as long as necessary to provide the
        Services or as required by law. You may request deletion of your data at
        any time by contacting us.
      </p>

      <h2>7. Your Rights</h2>
      <p>Depending on your location, you may have the right to:</p>
      <ul>
        <li>Access, correct, or delete your personal information.</li>
        <li>Opt-out of marketing communications.</li>
        <li>Restrict or object to data processing.</li>
        <li>Request data portability.</li>
      </ul>
      <p>To exercise these rights, contact us at admin@velocitymgr.com.</p>

      <h2>8. Third-Party Links</h2>
      <p>
        The App may include links to third-party websites or services. We are
        not responsible for their privacy practices. Please review their
        policies before providing your information.
      </p>

      <h2>9. Children’s Privacy</h2>
      <p>
        The Services are not intended for children under 13. We do not knowingly
        collect personal information from children. If you believe we have
        collected such information, contact us immediately.
      </p>

      <h2>10. Changes to This Policy</h2>
      <p>
        We may update this Privacy Policy periodically. We will notify you of
        material changes via email or through the App. Your continued use of the
        Services constitutes acceptance of the updated policy.
      </p>

      <h2>11. Contact Us</h2>
      <p>
        If you have questions or concerns about this Privacy Policy, contact us:
      </p>
      <ul>
        <li>
          <strong>Email:</strong>{' '}
          <a href='mailto:admin@velocitymgr.com'>admin@velocitymgr.com</a>
        </li>
        <li>
          <strong>Phone:</strong> +14385359593
        </li>
        <li>
          <strong>Mailing Address:</strong> 208 Pisces Terr, Gloucester, ON, K1T
          0S8
        </li>
      </ul>

      <p>
        <strong>
          By using Velocity Manager, you acknowledge that you have read and
          agree to this Privacy Policy.
        </strong>
      </p>
    </div>
  )
}
