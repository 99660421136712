import React from 'react';
import { Box, Typography } from '@mui/material';
import wobbling from '../assets/wobbling.svg';
import wobbling1 from '../assets/wobbling1.svg';
import Page from '../assets/homePage.svg';
import { Colors } from '../theme/Color';

const SpeedFlexibility = () => {
  return (
    <>
      <Box sx={{ background: Colors.candiDateBackground }}>
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            background: Colors.white,
            clipPath: 'polygon(0 0, 100% 0, 100% 85%, 0% 100%)',
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
            height: {
              md: '120vh',
              lg: '110vh',
              xl: '100vh',
            },
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '0px',
              right: '35%',
              width: '90vw',
              height: '100%',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                width: '45vw',
                top: '50%',
                left: '63%',
                transform: 'translate(-50%,-50%)',
                zIndex: '2',
              }}
            >
              <img
                style={{
                  width: '80%',
                  height: '100%',
                }}
                src={Page}
                alt='SpeedFlexibility'
                draggable={false}
              />
            </Box>
            <img
              style={{
                width: '76%',
                height: '100%',
                transform: 'rotate(-71.79deg)',
              }}
              src={wobbling}
              alt='wobbling'
              draggable={false}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              maxWidth: {
                md: '800px',
                lg: '980px',
                xl: '1240px',
              },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
              }}
            ></Box>
            <Box
              sx={{
                width: '50%',
                maxWidth: '560px',
              }}
            >
              <Typography
                sx={{
                  padding: '0px',
                  color: Colors.headingColor,
                  fontWeight: 900,
                  fontSize: {
                    md: '30px',
                    lg: '40px',
                    xl: '40px',
                  },
                  lineHeight: {
                    md: '45px',
                    lg: '51px',
                    xl: '60px',
                  },
                }}
              >
                Why customers love QuickBooks Time
              </Typography>
              <Typography
                sx={{
                  padding: 0,
                  color: Colors.grey,
                  paddingTop: '24px',
                  fontWeight: 500,
                  fontSize: {
                    md: '13px',
                    lg: '18px',
                    xl: '18px',
                  },
                  lineHeight: {
                    md: '20px',
                    lg: '21.78px',
                    xl: '21.78px',
                  },
                }}
              >
                QuickBooks Time users save time and money on payroll, adding an
                average of 11% more billable time to their invoices by tracking
                every second worked.
              </Typography>
              <Typography
                sx={{
                  padding: 0,
                  color: Colors.grey,
                  paddingTop: '15px',
                  fontWeight: 500,
                  fontSize: {
                    md: '13px',
                    lg: '18px',
                    xl: '18px',
                  },
                  lineHeight: {
                    md: '20px',
                    lg: '21.78px',
                    xl: '21.78px',
                  },
                }}
              >
                Thanks to over 20,000 five-star reviews across Capterra,
                Software Advice, and Apps.com, QuickBooks Time is the top-rated
                time tracking app. Over 1 million employees around the world use
                QuickBooks Time to track, manage and submit accurate timesheets.
              </Typography>
              <Typography
                sx={{
                  padding: 0,
                  color: Colors.grey,
                  paddingTop: '15px',
                  fontWeight: 500,
                  fontSize: {
                    md: '13px',
                    lg: '18px',
                    xl: '18px',
                  },
                  lineHeight: {
                    md: '20px',
                    lg: '21.78px',
                    xl: '21.78px',
                  },
                }}
              >
                QuickBooks Time surveyed 4,906 customers in the US in December
                2019 . On average, businesses that report gross payroll time
                savings save 3.15 hours. On average, businesses that report
                gross payroll cost savings save 5.14%. On average, businesses
                that report an increase in billable time added 11.46%
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Mobile */}
        <Box
          sx={{
            display: {
              xs: 'block',
              sm: 'block',
              md: 'none',
              lg: 'none',
              xl: 'none',
            },
            background: Colors.white,
            clipPath: {
              xs: 'polygon(0 0, 100% 0, 100% 93%, 0% 100%)',
              sm: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
            },
            height: '90vh',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '20px',
              maxWidth: {
                xs: '400px',
                sm: '700px',
              },
              margin: 'auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                margin: 'auto',
                flexDirection: 'column',
                maxWidth: {
                  xs: '400px',
                  sm: '570px',
                },
                paddingBottom: '2%',
              }}
            >
              <Typography
                sx={{
                  padding: '0px',
                  color: Colors.headingColor,
                  fontWeight: '700',
                  fontSize: {
                    xs: '22px',
                    sm: '22px',
                  },
                  lineHeight: {
                    xs: '35px',
                    sm: '35px',
                  },
                  textAlign: 'center',
                }}
              >
                Why customers love QuickBooks Time
              </Typography>
              <Typography
                sx={{
                  padding: 0,
                  color: Colors.grey,
                  paddingTop: '10px',
                  fontWeight: 400,
                  fontSize: {
                    xs: '12px',
                    sm: '14px',
                  },
                  lineHeight: {
                    xs: '20px',
                    sm: '20px',
                  },
                  textAlign: 'center',
                }}
              >
                QuickBooks Time users save time and money on payroll, adding an
                average of 11% more billable time to their invoices by tracking
                every second worked.*
              </Typography>
              <Typography
                sx={{
                  padding: 0,
                  color: Colors.grey,
                  paddingTop: '5px',
                  fontWeight: 400,
                  fontSize: {
                    xs: '12px',
                    sm: '14px',
                  },
                  lineHeight: {
                    xs: '20px',
                    sm: '20px',
                  },
                  textAlign: 'center',
                }}
              >
                Thanks to over 20,000 five-star reviews across Capterra,
                Software Advice, and Apps.com, QuickBooks Time is the top-rated
                time tracking app. Over 1 million employees around the world use
                QuickBooks Time to track, manage and submit accurate timesheets.
              </Typography>
              <Typography
                sx={{
                  padding: 0,
                  color: Colors.grey,
                  paddingTop: '5px',
                  fontWeight: 400,
                  fontSize: {
                    xs: '12px',
                    sm: '14px',
                  },
                  lineHeight: {
                    xs: '20px',
                    sm: '20px',
                  },
                  textAlign: 'center',
                }}
              >
                QuickBooks Time surveyed 4,906 customers in the US in December
                2019 . On average, businesses that report gross payroll time
                savings save 3.15 hours. On average, businesses that report
                gross payroll cost savings save 5.14%. On average, businesses
                that report an increase in billable time added 11.46%.
              </Typography>
            </Box>
            <Box
              sx={{
                height: {
                  xs: '43vh',
                  sm: '58vh',
                },
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: '350px',
                    sm: '550px',
                  },
                  height: {
                    xs: '350px',
                    sm: '450px',
                  },
                }}
              >
                <img
                  src={wobbling1}
                  style={{ width: '100%', height: '100%' }}
                  draggable={false}
                  alt='wobbling'
                />
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  top: '10%',
                  left: '16%',
                  width: '70%',
                  height: '80%',
                }}
              >
                <img
                  src={Page}
                  style={{ width: '100%', height: '100%' }}
                  draggable={false}
                  alt='page'
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SpeedFlexibility;
