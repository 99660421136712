import React from 'react';
import { Box, Card, CardContent, Typography, Grid2 } from '@mui/material';
import ButtonComponents from './ButtonComponents';
import { Colors } from '../theme/Color';
import CheckIcon from '@mui/icons-material/Check';

interface PricingOption {
  title: string;
  price: string;
  paymentText: string;
  aiFeatures: string[];
  buttonLabel: string;
  isPopular?: boolean;
}

const pricingOptions: PricingOption[] = [
  {
    title: 'Basic Plan',
    price: '£10/month',
    paymentText: 'per user',
    aiFeatures: [
      'Minimum of 5 Users',
      'AI Generated Job Descriptions',
      'AI Generated Interview Questions',
      'Bot Recorded Interviews',
      'Interview Transcriptions',
    ],
    buttonLabel: 'Register',
  },
  {
    title: 'Standard Plan',
    price: '£20/month',
    paymentText: 'per user',
    aiFeatures: [
      'Minimum of 5 Users',
      'Interview Transcriptions',
      'Integration and posting with major jobs boards',
      'Interviews by AI Generated Avatar',
      ' Everything from Free Plan',
      'Works out to be $180 per job',
    ],
    buttonLabel: 'Register',
    isPopular: true,
  },
  {
    title: 'Premium Plan',
    price: '£30/month',
    paymentText: 'per user',
    aiFeatures: [
      'Minimum of 5 Users',
      'Everything from Standard Plan',
      'Works out to be $150 per job',
    ],
    buttonLabel: 'Register ',
  },
];

const PricingCards: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          padding: {
            xs: '7rem 1rem 1rem 1rem',
            md: '10rem 5rem 3rem 5rem',
          },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: Colors.pricingSectionBackgorund,
        }}
      >
        <Grid2 container spacing={4} justifyContent='center'>
          {pricingOptions.map((option, index) => (
            <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }} key={index}>
              <Card
                sx={{
                  padding: '2rem',
                  height: {
                    // xs: '100%',
                    // sm: '100%',
                    // md: '100%',
                    lg: '80%',
                    xl: '80%',
                  },
                  width: {
                    // xs: '100%',
                    // sm: '100%',
                    // md: '100%',
                    lg: '80%',
                    xl: '80%',
                  },
                  position: 'relative',
                  backgroundColor: Colors.white,
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  borderRadius: '12px',
                  border: '1px solid #EC7211',
                  transition: 'transform 0.3s ease-in-out',
                  transform: option.isPopular ? 'scale(1.05)' : 'scale(1)',
                  '&:hover': {
                    transform: option.isPopular ? 'scale(1.05)' : 'scale(1.05)',
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant='h6'
                    sx={{
                      color: Colors.slate_black,
                      fontWeight: 500,
                      textAlign: {
                        xs: 'center',
                        sm: 'center',
                        md: 'center',
                      },
                      fontSize: {
                        xs: '1rem',
                        sm: '1rem',
                        md: '1.4rem',
                        lg: '1.5rem',
                        xl: '1.5rem',
                      },
                      marginBottom: '0.5rem',
                    }}
                  >
                    {option.title}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: {
                        xs: 'column',
                        sm: 'column',
                        md: 'row',
                        lg: 'row',
                        xl: 'row',
                      },
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.headingColor,
                        fontWeight: 700,
                        fontSize: {
                          xs: '2.5rem',
                          sm: '2.5rem',
                          md: '2rem',
                          lg: '2rem',
                          xl: '2rem',
                        },
                        marginBottom: {
                          xs: '0.5rem',
                          sm: '0.5rem',
                          md: '0',
                          lg: '0',
                          xl: '0',
                        },
                        paddingRight: '10px',
                      }}
                    >
                      {option.price}
                    </Typography>
                    <Typography
                      sx={{
                        color: Colors.grey,
                        fontWeight: 400,
                        fontSize: {
                          xs: '1rem',
                          sm: '1rem',
                          md: '1.125rem',
                          lg: '1.125rem',
                          xl: '1.125rem',
                        },
                        marginBottom: '1rem',
                      }}
                    >
                      {option.paymentText}
                    </Typography>
                  </Box>
                  <ButtonComponents
                    text={option.buttonLabel}
                    color={Colors.white}
                    background={`linear-gradient(to right, ${Colors.vivid_mulberry}, ${Colors.slate_blue})`}
                    width='100%'
                  />
                  <Box
                    sx={{
                      marginTop: '1rem',
                    }}
                  >
                    <Typography
                      variant='h6'
                      sx={{
                        fontWeight: 400,
                        marginBottom: '0.5rem',
                        color: Colors.slate_black,
                      }}
                    >
                      Includes:
                    </Typography>
                    {option.aiFeatures.map((feature, i) => (
                      <Box
                        key={i}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '0.5rem',
                        }}
                      >
                        <CheckIcon
                          sx={{
                            color: Colors.slate_blue,
                            marginRight: '0.5rem',
                            fontSize: '1.2rem',
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: {
                              xs: '1rem',
                              sm: '1rem',
                              md: '1rem',
                              lg: '1rem',
                              xl: '1rem',
                            },
                            color: Colors.grey,
                          }}
                        >
                          {feature}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </Box>
    </>
  );
};

export default PricingCards;
