import React from 'react';
import { Box, Typography, Grid, Card } from '@mui/material';
import { Colors } from '../theme/Color';
import Prepare from '../assets/prepare.svg';
import PreScreen from '../assets/pre-Screen.svg';
import Assess from '../assets/assess.svg';
import Report from '../assets/reports-solid.svg';

interface dataType {
  title: string;
  src: any;
  discription: string;
}

const Data: dataType[] = [
  {
    title: 'Mobile App',
    src: Prepare,
    discription:
      'Download our time clock app for Android or iOS app to track, submit, and approve time from anywhere.',
  },
  {
    title: 'Scheduling',
    src: PreScreen,
    discription:
      'Build employee schedules by jobs or shifts. Quickly and easily edit, publish, and share the schedule with your team.',
  },
  {
    title: 'Team Management',
    src: Assess,
    discription:
      'Keep your team in the know with project management and geofencing technology. See the status of all your projects at a glance.',
  },
  {
    title: 'Reports',
    src: Report,
    discription:
      'Gain valuable business insight with real-time, interactive reports. Predict time needs for job costs, plan for payroll and increase profitability.',
  },
];

const TopTalent = () => {
  return (
    <Box sx={{ background: Colors.candiDateBackground }}>
      <Box
        sx={{
          background: Colors.white,
          clipPath: {
            xs: 'polygon(0 0, 100% 0, 100% 97%, 0% 100%)',
            sm: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
            md: 'polygon(0 0, 100% 0, 100% 85%, 0% 100%)',
            lg: 'polygon(0 0, 100% 0, 100% 85%, 0% 100%)',
            xl: 'polygon(0 0, 100% 0, 100% 85%, 0% 100%)',
          },
          padding: { xs: '20px 45px', md: '0' },
          // height: '100vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            textAlign: 'center',
            paddingTop: '2%',
            maxWidth: { md: '800px', lg: '1200px', xl: '1240px' },
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                color: Colors.headingColor,
                fontWeight: '900',
                fontSize: { xs: '22px', md: '30px', lg: '40px' },
                lineHeight: { xs: '35px', md: '50px', lg: '64.17px' },
              }}
            >
              Time tracking software that simplifies your business
            </Typography>
            <Grid
              container
              spacing={5}
              sx={{
                paddingTop: { xs: '28px', md: '40px' },
                paddingBottom: '17%',
              }}
            >
              {Data.map((menu: dataType, index: number) => (
                <Grid item key={index} xl={3} lg={3} md={3}>
                  <Card
                    sx={{
                      height: { xs: '250px', md: '330px', lg: '350px' },
                      background: '#FFFFFF',
                      // border: '1px solid #E5E5E5',
                      boxShadow: '0 0 5px 3px rgba(0, 0, 0, 0.1)',
                      borderRadius: '10px',
                      padding: {
                        xs: '18px 10px',
                        md: '25px 15px',
                        lg: '40px 20px',
                      },
                    }}
                  >
                    <img src={menu.src} alt={menu.title} draggable={false} />
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: { xs: '16px', md: '16px', lg: '22px' },
                        lineHeight: { xs: '22px', md: '21px' },
                        color: Colors.textColor,
                        paddingTop: { xs: '7px', md: '15px' },
                      }}
                    >
                      {menu.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: {
                          xs: '13px',
                          md: '14px',
                          lg: '18px',
                          xl: '18px',
                        },
                        lineHeight: {
                          xs: '20px',
                          md: '21.78px',
                          lg: '21.78px',
                          xl: '21.78px',
                        },
                        textAlign: 'center',
                        padding: '15px',
                        color: Colors.descriptionTextColor,
                      }}
                    >
                      {menu.discription}
                    </Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TopTalent;
