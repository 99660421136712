import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Colors } from '../theme/Color';
import facebook from '../assets/facebook.svg';
import linkedin from '../assets/linkedin.svg';
import call from '../assets/call.svg';
import mail from '../assets/mail.svg';
// import address from '../assets/address.svg';
import playstore from '../assets/playstore.svg';
import appstore from '../assets/appstore.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';
import { APPLE_URL, PLAYSTORE_URL } from '../const';
import logo from '../assets/logo.png';

interface menuType {
  name: string;
  url: string;
}

const firstColumn = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Products',
    url: '/products',
  },
  {
    name: 'Pricing',
    url: '/pricing',
  },
];

const secondColumn = [
  {
    name: 'About Us',
    url: '/about-us',
  },
  {
    name: 'Contact',
    url: '/contact-us',
  },
  {
    name: 'Privacy Policy',
    url: '/privacy-policy',
  },
  {
    name: 'Term of Use',
    url: '/terms-and-conditions',
  },
];

const Footer = () => {
  const selectpath = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{ background: Colors.white }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            margin: 'auto',
            paddingBottom: '8%',
            maxWidth: {
              md: '800px',
              lg: '980px',
              xl: '1240px',
            },
            flexDirection: {
              xs: 'column',
              sm: 'row',
              md: 'row',
              lg: 'row',
              xl: 'row',
            },
            gap: {
              xs: '40px',
              sm: '40px',
            },
            padding: {
              xs: '20px',
              sm: '20px',
            },
            flexWrap: {
              sm: 'wrap',
            },
          }}
        >
          <Box
            sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '21%',
                lg: '21%',
                xl: '21%',
              },
            }}
          >
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/');
              }}
            >
              <img
                // style={{ width: '3.2rem', height: '3.2rem' }}
                src={logo}
                alt=''
                width={120}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                alignItems: {
                  xs: 'center',
                  sm: 'center',
                  md: 'center',
                  lg: 'start',
                  xl: 'start',
                },
                // paddingTop: {
                //   xs: '20px',
                //   sm: '20px',
                //   md: '30px',
                //   lg: '30px',
                //   xl: '30px',
                // },
              }}
            >
              <a href='https://www.facebook.com/'>
                <img src={facebook} alt='facebook' draggable={false} />
              </a>
              <a href='https://www.linkedin.com/'>
                <img src={linkedin} alt='linkedin' draggable={false} />
              </a>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                alignItems: {
                  xs: 'center',
                  sm: 'center',
                  md: 'center',
                  lg: 'start',
                  xl: 'start',
                },
                paddingTop: {
                  xs: '20px',
                  sm: '20px',
                  md: '30px',
                  lg: '30px',
                  xl: '30px',
                },
              }}
            >
              <a href={PLAYSTORE_URL}>
                <img src={playstore} alt='facebook' draggable={false} />
              </a>
              <a href={APPLE_URL}>
                <img src={appstore} alt='linkedin' draggable={false} />
              </a>
            </Box>
          </Box>
          <Box
            sx={{
              width: {
                md: '21%',
                lg: '21%',
                xl: '21%',
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: {
                  xs: '20px',
                  sm: '20px',
                  md: '24px',
                  lg: '24px',
                  xl: '24px',
                },
                // lineHeight: '30px',
                // background: Colors.footerBackgroundColor,
                // WebkitBackgroundClip: "text",
                // WebkitTextFillColor: "transparent",
                backgroundClip: 'text',
                textFillColor: '#EC7211',
              }}
            >
              Quick Links
            </Typography>
            <Stack
              direction={'row'}
              spacing={'50px'}
              sx={{
                paddingTop: {
                  xs: '20px',
                  sm: '20px',
                  md: '35px',
                  lg: '35px',
                  xl: '35px',
                },
              }}
            >
              <Stack direction={'column'} spacing={'20px'}>
                {firstColumn.map((menu: menuType, index: number) => (
                  <Box
                    key={index}
                    onClick={() => {
                      navigate(menu.url);
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: {
                          xs: '14px',
                          sm: '14px',
                          md: '16px',
                          lg: '18px',
                          xl: '18px',
                        },
                        lineHeight: '28.8px',
                        padding: 0,
                        cursor: 'pointer',
                        color:
                          selectpath.pathname === menu.url
                            ? Colors.black
                            : Colors.grey,
                      }}
                    >
                      {menu.name}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Stack>
          </Box>
          <Box
            sx={{
              width: {
                md: '21%',
                lg: '21%',
                xl: '21%',
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: {
                  xs: '20px',
                  sm: '20px',
                  md: '24px',
                  lg: '24px',
                  xl: '24px',
                },
                // lineHeight: '30px',
                // background: Colors.footerBackgroundColor,
                // WebkitBackgroundClip: "text",
                // WebkitTextFillColor: "transparent",
                backgroundClip: 'text',
                textFillColor: '#EC7211',
              }}
            >
              About
            </Typography>
            <Stack
              direction={'row'}
              spacing={'50px'}
              sx={{
                paddingTop: {
                  xs: '20px',
                  sm: '20px',
                  md: '35px',
                  lg: '35px',
                  xl: '35px',
                },
              }}
            >
              <Stack direction={'column'} spacing={'20px'}>
                {secondColumn.map((menu: menuType, index: number) => (
                  <Box
                    key={index}
                    onClick={() => {
                      navigate(menu.url);
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: {
                          xs: '14px',
                          sm: '14px',
                          md: '16px',
                          lg: '18px',
                          xl: '18px',
                        },
                        lineHeight: '28.8px',
                        padding: 0,
                        cursor: 'pointer',
                        color:
                          selectpath.pathname === menu.url
                            ? Colors.black
                            : Colors.grey,
                      }}
                    >
                      {menu.name}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Stack>
          </Box>
          <Box
            sx={{
              width: {
                md: '21%',
                lg: '21%',
                xl: '21%',
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: {
                  xs: '20px',
                  sm: '20px',
                  md: '24px',
                  lg: '24px',
                  xl: '24px',
                },
                // lineHeight: '30px',
                // background: Colors.footerBackgroundColor,
                // WebkitBackgroundClip: "text",
                // WebkitTextFillColor: "transparent",
                backgroundClip: 'text',
                textFillColor: '#EC7211',
              }}
            >
              Contact
            </Typography>
            <Box
              sx={{
                width: '100%',
                gap: '15px',
                display: 'flex',
                paddingTop: {
                  xs: '20px',
                  sm: '20px',
                  md: '35px',
                  lg: '35px',
                  xl: '35px',
                },
              }}
            >
              <img src={call} alt='call' draggable={false} />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: {
                    xs: '14px',
                    sm: '14px',
                    md: '16px',
                    lg: '18px',
                    xl: '18px',
                  },
                  lineHeight: '28.8px',
                  padding: 0,
                  color: Colors.grey,
                }}
              >
                <a href='tel:+14385359593' style={{ textDecoration: 'none' }}>
                  +1 438 5359 593
                </a>
              </Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                gap: '15px',
                display: 'flex',
                paddingTop: '25px',
              }}
            >
              <img src={mail} alt='mail' draggable={false} />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: {
                    xs: '14px',
                    sm: '14px',
                    md: '16px',
                    lg: '18px',
                    xl: '18px',
                  },
                  lineHeight: '28.8px',
                  padding: 0,
                  color: Colors.grey,
                }}
              >
                <a
                  href='mailto:support@velocitymgr.com'
                  style={{ textDecoration: 'none' }}
                >
                  support@velocitymgr.com
                </a>
              </Typography>
            </Box>
            {/* <Box
              sx={{
                width: '100%',
                gap: '15px',
                display: 'flex',
                paddingTop: '25px',
              }}
            >
              <img src={address} alt='address' draggable={false} />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: {
                    xs: '14px',
                    sm: '14px',
                    md: '16px',
                    lg: '18px',
                    xl: '18px',
                  },
                  lineHeight: '28.8px',
                  padding: 0,
                  color: Colors.grey,
                }}
              >
                Lorim ipsum is dummy text of the printing and typesetting
                industry.
              </Typography>
            </Box> */}
          </Box>
        </Box>
      </Box>
      <PrivacyPolicy />
    </>
  );
};

export default Footer;
