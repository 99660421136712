export const Colors = {
  vivid_mulberry: '#72380A',
  white: '#FFFFFF',
  black: '#000000',
  grey: '#838383',
  white_Background: '#FFFFFF',
  //backgroundColor: "linear-gradient(111.34deg, #F9F0FD 0%, #EDE9F9 100%)",
  backgroundColor: 'linear-gradient(111.34deg, #FFFFFF 0%, #F4F4F4 100%)',
  headingColor: '#EC7211',
  altHeadingColor: '#FFFFFF',
  slate_blue: '#EC7211',
  slate_black: '#000000',
  slate_grey: '#A0A0A0',
  pricingSectionBackgorund: '#F7F7FC',
  // candiDateBackground: "#F7F7FC",
  candiDateBackground: '#FFEBDB',
  alternateSectionBackground: '#EC7211',
  textColor: '#EC7211',
  alttextColor: '#FFFFFF',
  descriptionTextColor: '#A0A0A0',
  footerBackgroundColor:
    'linear-gradient(94.23deg, #07042B 0%, #6559D7 99.43%)', //#AA19D5
  placeholderTextColor: '#DBDBDB',
  textAreaBackgroundColor: '#F9F9F9',
  dark_orchid: '#A51ED4',
  blue_primary: '#2e88dd',
  alice_blue: '#EBF2FF',
};
