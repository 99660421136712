import { Box, Typography } from '@mui/material';
import React from 'react';
import { Colors } from '../../theme/Color';

function EmployeeExplore() {
  return (
    <Box
      sx={{
        background: Colors.candiDateBackground,
        width: '100%',
        height: {
          xs: '80vh',
          md: '100vh',
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Typography
        sx={{
          padding: { xs: '20px', sm: '20px', md: '0px' },
          fontWeight: 700,
          color: Colors.headingColor,
          fontSize: {
            xs: '24px',
            sm: '32px',
            md: '30px',
            lg: '40px',
            xl: '40px',
          },
          lineHeight: {
            xs: '32px',
            sm: '40px',
            md: '48px',
            lg: '55px',
            xl: '60px',
          },
          marginBottom: '16px',
        }}
      >
        Learn about our Employee Time Clock in 2 minutes
      </Typography>

      <Box
        sx={{
          width: { xs: '90%', md: '60%', lg: '40%' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <iframe
          width='100%'
          height='415'
          style={{
            border: 0,
            borderRadius: 6,
            backgroundColor: Colors.textColor,
          }}
          src='https://player.vimeo.com/video/831789635'
          title='Velocity - The future. AI Generated Copilot for Recruiters'
          allowFullScreen={false}
          allow='autoplay; encrypted-media'
        ></iframe>
      </Box>
    </Box>
  );
}

export default EmployeeExplore;
