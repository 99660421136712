import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import HardWork from '../components/HardWork';
import Candidates from '../components/Candidates';
import TopTalent from '../components/TopTalent';
import RecruitmentSoftware from '../components/RecruitmentSoftware';
import SpeedFlexibility from '../components/SpeedFlexibility';
import SaveTime from '../components/SaveTime';
import HireSmarterAchieve from '../components/HireSmarterAchieve';

declare global {
  interface Window {
    hbspt: any;
  }
}

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          overflowX: 'hidden',
        }}
      >
        {/* HardWork */}
        <HardWork />

        {/*Candidates */}
        <Candidates />

        {/* TopTalent */}
        <TopTalent />

        {/* RecruitmentSoftware */}
        <RecruitmentSoftware />

        {/* SpeedFlexibility why customer*/}
        <SpeedFlexibility />

        {/* SpeedFlexibility */}
        {/* <PricingCards /> */}

        {/* SaveTime cards*/}
        <SaveTime />

        {/* HireSmarterAchieve slider */}
        <HireSmarterAchieve />
      </Box>
    </>
  );
};

export default Home;
