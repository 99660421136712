import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Contact from '../pages/Contact';
import AboutUs from '../pages/AboutUs';
import PricingCards from '../components/PricingCards';
import Products from '../pages/Products';
import TimeClock from '../components/TimeClock';
import { PrivacyPolicy } from '../pages/PrivacyPolicy';
import { Terms } from '../pages/Terms';

const WebRoutes = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/products' element={<Products />} />
        <Route path='/time-clock' element={<TimeClock />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/pricing' element={<PricingCards />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-and-conditions' element={<Terms />} />

        {/* Not Found page */}
        {/* <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
    </>
  );
};

export default WebRoutes;
