import React from 'react';
import {
  Typography,
  Box,
  Card,
  CardContent,
  Avatar,
  Grid2,
} from '@mui/material';
import { Colors } from '../theme/Color';
import contact from '../assets/contact1.jpg';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'; // Example for sales
import HandshakeIcon from '@mui/icons-material/Handshake';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import ContactForm from '../components/ContactForm';

interface PricingOption {
  title: string;
  paymentText: string;
  icon: JSX.Element;
}

const pricingOptions: PricingOption[] = [
  {
    title: 'Contact Sales',
    paymentText: 'Interested in Connectteam?',
    icon: <PhoneInTalkIcon />,
  },
  {
    title: 'Contact Support',
    paymentText: 'Already have an account?',
    icon: <HeadsetMicIcon />,
  },
  {
    title: 'Become to partner',
    paymentText: 'Want to partner with us?',
    icon: <HandshakeIcon />,
  },
];

const Contact = ({ firstRef1 }: any) => {
  return (
    <>
      {/* Hero Section Contact...////// */}
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          background: Colors.white,
          display: {
            xs: 'block',
            sm: 'block',
            md: 'block',
            lg: 'block',
            xl: 'block',
          },
        }}
        ref={firstRef1}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: {
              xs: 'center',
              sm: 'center',
              md: 'center',
              lg: 'space-around',
              xl: 'space-around',
            },
            flexDirection: {
              xs: 'column',
              sm: 'column',
              md: 'column',
              lg: 'row',
              xl: 'row',
            },
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <Typography
            sx={{
              paddingTop: '100px',
              color: Colors.headingColor,
              textAlign: 'center',
              fontWeight: '500',
              fontSize: {
                md: '40px',
                lg: '40px',
                xl: '40px',
              },
              lineHeight: {
                md: '45px',
                lg: '51px',
                xl: '60px',
              },
            }}
          >
            We want to hear from you!
          </Typography>
          <Box
            component='img'
            sx={{
              paddingTop: {
                xs: '50px',
                sm: '50px',
                md: '50px',
                lg: '100px',
                xl: '100px',
              },
              width: {
                xs: '40%',
                // md: '100%',
              },
              height: '65%',
              objectFit: 'contain',
            }}
            src={contact}
            alt='candidate'
            draggable={false}
          />
        </Box>
      </Box>
      <Box
        sx={{
          padding: '5rem 3rem',
          background: Colors.pricingSectionBackgorund,
        }}
      >
        <Grid2 container spacing={2} justifyContent='center'>
          {pricingOptions.map((option, index) => (
            <Grid2 size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Card
                sx={{
                  padding: '15px',
                  height: '80%',
                  position: 'relative',
                  backgroundColor: Colors.white,
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  borderRadius: '12px',
                }}
              >
                <CardContent>
                  <Avatar sx={{ bgcolor: '#EC7211', marginBottom: '2rem' }}>
                    {option.icon}
                  </Avatar>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingTop: '50px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.slate_grey,
                        fontWeight: '200',
                        fontSize: '1rem',
                      }}
                    >
                      {option.paymentText}
                    </Typography>
                  </Box>
                  <Typography
                    variant='h6'
                    sx={{
                      color: Colors.slate_black,
                      fontWeight: '500',
                      fontSize: '1.3rem',
                      marginBottom: '1rem',
                    }}
                  >
                    {option.title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid2>
          ))}
        </Grid2>
        <Box
          sx={{
            padding: '5rem 5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Avatar
            sx={{
              bgcolor: '#EC7211 ',
              marginBottom: '1rem',
            }}
          >
            <LocationOnIcon />
          </Avatar>
          <Typography
            sx={{
              color: Colors.slate_grey,
              textAlign: 'center',
              fontWeight: '500',
              fontSize: {
                md: '20px',
                lg: '20px',
                xl: '20px',
              },
              lineHeight: {
                md: '45px',
                lg: '51px',
                xl: '60px',
              },
            }}
          >
            Lorem ipsum dolor sit amet consectetur adipisicing.
          </Typography>
        </Box>
      </Box>
      <ContactForm />
    </>
  );
};

export default Contact;
