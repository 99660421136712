import React from 'react';
import { Box, Typography } from '@mui/material';
import { Colors } from '../theme/Color';
import First from '../assets/first.svg';
import Second from '../assets/second.svg';
import Third from '../assets/third.svg';
import Forth from '../assets/fourth.svg';
import ButtonComponents from './ButtonComponents';
import { useNavigate } from 'react-router-dom';

const SaveTime = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        background: Colors.candiDateBackground,
        clipPath: {
          xs: 'polygon(0 0, 100% 0, 100% 95%, 0% 100%)',
          sm: 'polygon(0 0, 100% 0, 100% 95%, 0% 100%)',
          md: 'polygon(0 0, 100% 0, 100% 85%, 0% 100%)',
          lg: 'polygon(0 0, 100% 0, 100% 85%, 0% 100%)',
          xl: 'polygon(0 0, 100% 0, 100% 85%, 0% 100%)',
        },
        paddingBottom: {
          xs: '26%',
          sm: '26%',
          md: '6%',
          lg: '2%',
          xl: '6%',
        },
        paddingTop: '4%',
        height: {
          // xs: '100%',
          // sm: '100%',
          md: '200vh',
          lg: '140vh',
          xl: '110vh',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: 'auto',
          maxWidth: {
            xs: '400px',
            sm: '570px',
            md: '800px',
            lg: '1200px',
            xl: '1240px',
          },
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'column',
            lg: 'row',
            xl: 'row',
          },
          padding: {
            xs: '20px',
            sm: '20px',
            md: '0',
            lg: '0',
            xl: '0',
          },
          paddingBottom: '4%',
        }}
      >
        <Box
          sx={{
            width: {
              xs: '100%',
              md: '100%',
              lg: '50%',
              xl: '45%',
            },
            textAlign: {
              xs: 'center',
              md: 'center',
            },
            paddingBottom: {
              md: '20px',
            },

            marginRight: {
              xs: '0px',

              md: '0px',
              lg: '100px',
              xl: '100px',
            },
          }}
        >
          <Typography
            sx={{
              padding: '0px',
              color: Colors.headingColor,
              fontWeight: 900,
              fontSize: {
                xs: '22px',
                sm: '22px',
                md: '30px',
                lg: '40px',
                xl: '40px',
              },
              lineHeight: {
                xs: '35px',
                sm: '35px',
                md: '64.17px',
                lg: '64.17px',
                xl: '64.17px',
              },
              textAlign: {
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'start',
                xl: 'start',
              },
            }}
          >
            QuickBooks Time job scheduling features
          </Typography>
          <Typography
            sx={{
              padding: 0,
              color: Colors.grey,
              paddingTop: {
                xs: '5px',
                sm: '7px',
                md: '13px',
                lg: '16px',
                xl: '16px',
              },
              fontWeight: 500,
              fontSize: {
                xs: '12px',
                sm: '14px',
                md: '18px',
                lg: '18px',
                xl: '18px',
              },
              lineHeight: {
                xs: '20px',
                sm: '20px',
                md: '21.78px',
                lg: '21.78px',
                xl: '21.78px',
              },
              textAlign: {
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'start',
                xl: 'start',
              },
            }}
          >
            Time tracking apps for all devices are essential tools for
            maximizing productivity and managing time effectively. Whether
            you’re on your smartphone, tablet, or desktop, these apps allow you
            to monitor how you spend your hours, helping you identify
            time-wasting activities and streamline your workflow. With features
            like project tracking, detailed reports, and reminders, they adapt
            to your lifestyle, ensuring you stay organized no matter where you
            are.
          </Typography>
          <Box
            sx={{
              paddingTop: {
                md: '20px',
                lg: '50px',
                xl: '50px',
              },
              display: {
                xs: 'flex',
                sm: 'flex',
              },
              alignItems: {
                xs: 'center',
                sm: 'center',
              },
              justifyContent: {
                xs: 'center',
                sm: 'center',
                md: 'start',
                lg: 'start',
                xl: 'start',
              },
              paddingY: {
                xs: '20px',
                sm: '20px',
              },
            }}
          >
            <ButtonComponents
              text='Get Your Demo'
              color={Colors.white}
              background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
              width='191px'
              onclick={() => navigate('book-demo')}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '50%',
              xl: '50%',
            },
            height: '100%',
            paddingX: {
              md: '30px',
              lg: '0',
              xl: '0',
            },
            display: {
              md: 'flex',
            },
            alignItems: {
              md: 'center',
            },
            justifyContent: {
              md: 'center',
            },
            flexDirection: {
              md: 'column',
            },
            paddingBottom: {
              md: '10%',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              width: '100%',
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
                xl: 'row',
              },
            }}
          >
            <Box
              sx={{
                background: Colors.white,
                boxShadow: '0 0 5px 5px rgba(0, 0, 0, 0.1)',
                borderRadius: '40px 40px 0px 40px',
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '50%',
                  lg: '50%',
                  xl: '50%',
                },
                height: '350px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                paddingTop: '10px',
              }}
            >
              <img src={First} alt='First' draggable={false} />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: {
                    xs: '20px',
                    sm: '20px',
                    md: '20px',
                    lg: '24px',
                    xl: '24px',
                  },
                  lineHeight: '30px',
                  textAlign: 'center',
                  color: '#EC7211',
                  paddingTop: {
                    xs: '20px',
                    sm: '20px',
                    md: '10px',
                    lg: '10px',
                    xl: '10px',
                  },
                }}
              >
                Schedule in the <br /> app or online
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: {
                    xs: '16px',
                    sm: '16px',
                    md: '18px',
                    lg: '18px',
                    xl: '18px',
                  },
                  lineHeight: '21.78px',
                  textAlign: 'center',
                  color: Colors.grey,
                  padding: {
                    xs: '30px',
                    sm: '30px',
                    md: '20px',
                    lg: '20px',
                    xl: '20px',
                  },
                }}
              >
                You don’t need an office to plan and set the schedule. Do it on
                the go in the QuickBooks Time mobile app.
              </Typography>
            </Box>
            <Box
              sx={{
                background: Colors.white,
                boxShadow: '0 0 5px 5px rgba(0, 0, 0, 0.1)',
                borderRadius: '40px 40px 40px 0px',
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '50%',
                  lg: '50%',
                  xl: '50%',
                },
                height: '350px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                paddingTop: '10px',
              }}
            >
              <img src={Second} alt='Second' draggable={false} />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: {
                    xs: '20px',
                    sm: '20px',
                    md: '20px',
                    lg: '24px',
                    xl: '24px',
                  },
                  lineHeight: '30px',
                  textAlign: 'center',
                  color: '#EC7211',
                  paddingTop: {
                    xs: '20px',
                    sm: '20px',
                    md: '10px',
                    lg: '10px',
                    xl: '10px',
                  },
                }}
              >
                Drag-and-drop scheduling
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: {
                    xs: '16px',
                    sm: '16px',
                    md: '18px',
                    lg: '18px',
                    xl: '18px',
                  },
                  lineHeight: '21.78px',
                  textAlign: 'center',
                  color: Colors.grey,
                  padding: {
                    xs: '30px',
                    sm: '30px',
                    md: '20px',
                    lg: '20px',
                    xl: '20px',
                  },
                }}
              >
                Schedule jobs in seconds. Drag and drop jobs from previous
                schedules or easily repeat last week’s schedule.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              paddingTop: '20px',
              width: '100%',
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
                xl: 'row',
              },
            }}
          >
            <Box
              sx={{
                background: Colors.white,
                boxShadow: '0 0 5px 5px rgba(0, 0, 0, 0.1)',
                borderRadius: '40px 0px 40px 40px',
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '50%',
                  lg: '50%',
                  xl: '50%',
                },
                height: '350px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                paddingTop: '10px',
              }}
            >
              <img src={Third} alt='Forth' draggable={false} />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: {
                    xs: '20px',
                    sm: '20px',
                    md: '20px',
                    lg: '24px',
                    xl: '24px',
                  },
                  lineHeight: '30px',
                  textAlign: 'center',
                  color: '#EC7211',
                  paddingTop: {
                    xs: '20px',
                    sm: '20px',
                    md: '10px',
                    lg: '10px',
                    xl: '10px',
                  },
                }}
              >
                Simple record- <br /> keeping
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: {
                    xs: '16px',
                    sm: '16px',
                    md: '18px',
                    lg: '18px',
                    xl: '18px',
                  },
                  lineHeight: '21.78px',
                  textAlign: 'center',
                  color: Colors.grey,
                  padding: {
                    xs: '30px',
                    sm: '30px',
                    md: '20px',
                    lg: '20px',
                    xl: '20px',
                  },
                }}
              >
                QuickBooks Time stores everything in the cloud for easy access
                when you need it.
              </Typography>
            </Box>
            <Box
              sx={{
                background: Colors.white,
                boxShadow: '0 0 5px 5px rgba(0, 0, 0, 0.1)',
                borderRadius: '0px 40px 40px 40px',
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '50%',
                  lg: '50%',
                  xl: '50%',
                },
                height: '350px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                paddingTop: '10px',
              }}
            >
              <img src={Forth} alt='Forth' draggable={false} />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: {
                    xs: '20px',
                    sm: '20px',
                    md: '20px',
                    lg: '24px',
                    xl: '24px',
                  },
                  lineHeight: '30px',
                  textAlign: 'center',
                  color: '#EC7211',
                  paddingTop: {
                    xs: '20px',
                    sm: '20px',
                    md: '10px',
                    lg: '10px',
                    xl: '10px',
                  },
                }}
              >
                Text or email notifications
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: {
                    xs: '16px',
                    sm: '16px',
                    md: '18px',
                    lg: '18px',
                    xl: '18px',
                  },
                  lineHeight: '21.78px',
                  textAlign: 'center',
                  color: Colors.grey,
                  padding: {
                    xs: '30px',
                    sm: '30px',
                    md: '20px',
                    lg: '20px',
                    xl: '20px',
                  },
                }}
              >
                Notify teams of upcoming jobs and shifts via customized text,
                email or push notifications.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SaveTime;
