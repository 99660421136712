import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import wobbling from '../assets/wobbling.svg';
import wobbling1 from '../assets/wobbling1.svg';
import HomePoster from '../assets/homeHero.png';

import ButtonComponents from './ButtonComponents';
import { useNavigate } from 'react-router-dom';
import '../../src/index.scss';
import { Colors } from '../theme/Color';
import playstore from '../assets/playstore.svg';
import appstore from '../assets/appstore.svg';
import { APPLE_URL, PLAYSTORE_URL } from '../const';

const HardWork = () => {
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    const playVideo = async () => {
      if (videoRef.current) {
        try {
          await videoRef.current.play();
        } catch (error) {
          console.error('Error playing video:', error);
        }
      }
    };

    const handleInteraction = () => {
      document.removeEventListener('click', handleInteraction);
      playVideo();
    };

    document.addEventListener('click', handleInteraction);

    return () => {
      document.removeEventListener('click', handleInteraction);
    };
  }, []);

  return (
    <Box sx={{ background: Colors.alternateSectionBackground }}>
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
            lg: 'block',
            xl: 'block',
          },
          background: Colors.backgroundColor,
          clipPath: 'polygon(0 0, 100% 0, 100% 85%, 0% 100%)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            maxWidth: {
              md: '800px',
              lg: '1200px',
              xl: '1240px',
            },
            height: '100vh',
            gap: '10px',
          }}
        >
          <Box
            sx={{
              width: '50%',
              maxWidth: '560px',
            }}
          >
            <Typography
              className='heading-Section-1'
              sx={{
                padding: '0px',
                color: Colors.headingColor,
                fontWeight: '900',
                fontSize: {
                  md: '30px',
                  lg: '30px',
                  xl: '30px',
                },
                lineHeight: {
                  md: '52px',
                  lg: '64.17px',
                  xl: '64.17px',
                },
              }}
            >
              Easy time tracking with over 20,000 five-star reviews
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.grey,
                paddingTop: '24px',
                fontWeight: 500,
                fontSize: {
                  md: '18px',
                  lg: '18px',
                  xl: '18px',
                },
                lineHeight: {
                  md: '21.78px',
                  lg: '21.78px',
                  xl: '21.78px',
                },
              }}
            >
              Easily track time on any device to streamline payroll, send
              accurate invoices and save thousands each year. Add Sehedule App
              to any subscription for a no-commitment, 30 days free trial.
            </Typography>
            <Box sx={{ display: 'flex', gap: '20px', paddingTop: '50px' }}>
              <ButtonComponents
                text='Get Your Demo'
                color={Colors.white}
                background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
                width='191px'
                onclick={() => navigate('book-demo')}
              />
              <ButtonComponents
                text='How it Works'
                color={Colors.black}
                background='transparent'
                borderColor={Colors.headingColor}
                width='190px'
                onclick={() => navigate('/learn-more')}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                alignItems: {
                  xs: 'center',
                  sm: 'center',
                  md: 'center',
                  lg: 'start',
                  xl: 'start',
                },
                paddingTop: {
                  xs: '20px',
                  sm: '20px',
                  md: '30px',
                  lg: '30px',
                  xl: '30px',
                },
              }}
            >
              <a href={PLAYSTORE_URL}>
                <img
                  src={playstore}
                  alt='facebook'
                  draggable={false}
                  width='150'
                />
              </a>
              <a href={APPLE_URL}>
                <img
                  src={appstore}
                  alt='linkedin'
                  draggable={false}
                  width='150'
                />
              </a>
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              width: '30%',
              marginLeft: '10%',
            }}
            className='videoTag'
          >
            <Box sx={{ width: '100%' }}>
              <img src={HomePoster} alt='' className='home-poster' />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            left: '35%',
            zIndex: '-1',
            width: '90vw',
            height: '100vh',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '45vw',
              height: '100vh',
              top: '50%',
              left: '41%',
              transform: 'translate(-50%,-50%)',
              // border: '1px solid black',
            }}
          ></Box>

          <img
            style={{
              width: '80%',
              height: '100%',
            }}
            src={wobbling}
            alt='wobbling'
            draggable={false}
          />
        </Box>
      </Box>

      {/* Mobile */}
      <Box
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'none',
            lg: 'none',
            xl: 'none',
          },
          background: Colors.backgroundColor,
          clipPath: {
            xs: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
            sm: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
          },
          height: '100vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '20px',
            paddingTop: {
              xs: '30%',
              sm: '15%',
            },
            maxWidth: {
              xs: '400px',
              sm: '700px',
            },
            margin: 'auto',
          }}
        >
          <Box
            sx={{
              fontWeight: 700,
              fontSize: {
                xs: '23px',
                sm: '30px',
              },
              lineHeight: {
                xs: '35px',
                sm: '45px',
              },
              textAlign: 'center',
              color: Colors.headingColor,
            }}
          >
            Easy time tracking with over 20,000 five-star reviews
          </Box>
          <Typography
            sx={{
              padding: 0,
              textAlign: 'center',
              color: Colors.grey,
              paddingTop: '24px',
              fontWeight: 400,
              fontSize: {
                md: '13px',
                lg: '15px',
                xl: '16px',
              },
              lineHeight: {
                md: '20px',
                lg: '20px',
                xl: '24px',
              },
            }}
          >
            Easily track time on any device to streamline payroll, send accurate
            invoices and save thousands each year. Add Sehedule App to any
            subscription for a no-commitment, 30 days free trial.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: '11%',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '80%',
                  sm: '65%',
                },
              }}
            >
              <img
                src={HomePoster}
                style={{ width: '100%', height: '100%' }}
                draggable={false}
                alt='homePoster'
              />
            </Box>
            <Box
              sx={{
                height: {
                  xs: '22vh',
                  sm: '40vh',
                },
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: -1,
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: '350px',
                    sm: '530px',
                  },
                  height: {
                    xs: '350px',
                    sm: '450px',
                  },
                }}
              >
                <img
                  src={wobbling1}
                  style={{ width: '100%', height: '100%' }}
                  draggable={false}
                  alt='wobbling'
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
              paddingTop: '10px',
              width: '100%',
              mt: '15%',
            }}
          >
            <ButtonComponents
              text='Get Your Demo'
              color={Colors.white}
              background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
              width='170px'
              onclick={() => navigate('book-demo')}
            />
            <ButtonComponents
              text='How it Works'
              color={Colors.vivid_mulberry}
              background='transparent'
              borderColor={Colors.vivid_mulberry}
              width='170px'
              onclick={() => navigate('/learn-more')}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HardWork;
