import React from 'react';
import { Box, Typography } from '@mui/material';
import { Colors } from '../theme/Color';
import wobbling from '../assets/wobbling.svg';

const Candidates = ({ firstRef1 }: any) => {
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          background: Colors.alternateSectionBackground,
          clipPath: 'polygon(0 0, 100% 0, 100% 85%, 0% 100%)',
          display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
            lg: 'block',
            xl: 'block',
          },
          height: {
            md: '100vh',
            lg: '90vh',
            xl: '90vh',
          },
        }}
        ref={firstRef1}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            right: '27%',
            width: '100vw',
            height: '100vh',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '45vw',
              top: '43%',
              left: '53%',
              transform: 'translate(-50%,-50%)',
              zIndex: '2',
            }}
          >
            <Box
              sx={{
                // flexGrow: 5,
                width: '30%',
                marginLeft: '10%',
              }}
              className='videoTag'
            >
              <Box sx={{ width: '100%' }}>
                <iframe
                  width='560'
                  height='315'
                  rel={'0'}
                  style={{
                    border: 0,
                    borderRadius: 6,
                    backgroundColor: '#000',
                  }}
                  src='https://player.vimeo.com/video/831789635'
                  title='Velocity - The future. AI Generated Copilot for Recruiters'
                  allowFullScreen={false}
                  allow='autoplay; encrypted-media'
                ></iframe>
              </Box>
            </Box>
          </Box>
          <img
            style={{
              width: '70%',
              height: '100%',
              transform: 'rotate(-65.79deg)',
            }}
            src={wobbling}
            alt='wobbling'
            draggable={false}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            maxWidth: {
              md: '800px',
              lg: '1110px',
              xl: '1240px',
            },
            height: '82vh',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
            }}
          ></Box>
          <Box
            sx={{
              width: '50%',
              maxWidth: '560px',
            }}
          >
            <Typography
              sx={{
                padding: '0px',
                color: Colors.altHeadingColor,
                fontWeight: 900,
                fontSize: {
                  md: '30px',
                  lg: '40px',
                  xl: '40px',
                },
                lineHeight: {
                  md: '45px',
                  lg: '64.17px',
                  xl: '64.17px',
                },
              }}
            >
              Know who’s on the job with GPS tracking
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.altHeadingColor,
                paddingTop: '24px',
                fontWeight: 500,
                fontSize: {
                  md: '16px',
                  lg: '18px',
                  xl: '18px',
                },
                lineHeight: {
                  md: '21.78px',
                  lg: '21.78px',
                  xl: '21.78px',
                },
              }}
            >
              Schedule App job scheduling software is perfect for crews on the
              move. When you get a call for a job, you’ll know in seconds who’s
              on the clock and who’s closest to the new gig.  
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.altHeadingColor,
                paddingTop: '12px',
                fontWeight: 500,
                fontSize: {
                  md: '16px',
                  lg: '18px',
                  xl: '18px',
                },
                lineHeight: {
                  md: '21.78px',
                  lg: '21.78px',
                  xl: '21.78px',
                },
              }}
            >
              Open the Who’s Working window to view GPS points for clocked-in
              employees. Add the new job to the schedule and assign it to a team
              or individual.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.altHeadingColor,
                paddingTop: '30px',
                fontWeight: 500,
                fontSize: {
                  md: '16px',
                  lg: '18px',
                  xl: '18px',
                },
                lineHeight: {
                  md: '21.78px',
                  lg: '21.78px',
                  xl: '21.78px',
                },
              }}
            >
              GPS tracking provides real-time visibility into who’s on the job,
              enhancing accountability and efficiency. Stay informed about
              employee locations to optimize operations and keep projects on
              track!
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Mobile */}
      <Box
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'none',
            lg: 'none',
            xl: 'none',
          },
          background: Colors.alternateSectionBackground,
          clipPath: {
            xs: 'polygon(0 0, 100% 0, 100% 93%, 0% 100%)',
            sm: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
          },

          height: '90vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '20px',
            maxWidth: {
              xs: '400px',
              sm: '700px',
            },
            margin: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              margin: 'auto',
              flexDirection: 'column',
              maxWidth: {
                xs: '400px',
                sm: '570px',
              },
              paddingBottom: '2%',
            }}
          >
            <Typography
              sx={{
                padding: '0px',
                color: Colors.altHeadingColor,
                fontWeight: '700',
                fontSize: {
                  xs: '22px',
                  sm: '22px',
                },
                lineHeight: {
                  xs: '35px',
                  sm: '35px',
                },
                textAlign: 'center',
              }}
            >
              Know who’s on the job with GPS tracking
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.alttextColor,
                paddingTop: '5px',
                ontWeight: 400,
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                },
                textAlign: 'center',
              }}
            >
              Schedule App job scheduling software is perfect for crews on the
              move. When you get a call for a job, you’ll know in seconds who’s
              on the clock and who’s closest to the new gig.  
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.alttextColor,
                paddingTop: '5px',
                ontWeight: 400,
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                },
                textAlign: 'center',
              }}
            >
              Open the Who’s Working window to view GPS points for clocked-in
              employees. Add the new job to the schedule and assign it to a team
              or individual.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.alttextColor,
                paddingTop: '5px',
                ontWeight: 400,
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                },
                textAlign: 'center',
              }}
            >
              GPS tracking provides real-time visibility into who’s on the job,
              enhancing accountability and efficiency. Stay informed about
              employee locations to optimize operations and keep projects on
              track!Tironem helps you focus on what matters - quickly uncovering
              top talent.
            </Typography>
          </Box>
          <Box
            sx={{
              height: {
                xs: '43vh',
                sm: '58vh',
              },
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                flexGrow: 5,
                width: '30%',
                margin: '10%',
              }}
              className='videoTag'
            >
              <Box
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    // md: '100%',
                    // lg: '100%',
                    // xl: '100%',
                  },
                }}
              >
                <iframe
                  width='290'
                  height='290'
                  rel={'0'}
                  style={{
                    border: 0,
                    borderRadius: 6,
                    backgroundColor: '#000',
                  }}
                  src='https://player.vimeo.com/video/831789635'
                  title='Velocity - The future. AI Generated Copilot for Recruiters'
                  allowFullScreen={false}
                  allow='autoplay; encrypted-media'
                ></iframe>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Candidates;
