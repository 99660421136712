import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import { Colors } from '../../theme/Color';
import ButtonComponents from '../ButtonComponents';

function TrackCard() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '30vh',
        padding: { xs: '16px', sm: '24px', md: '32px' },
        paddingBottom: '20px',
      }}
    >
      <Card
        sx={{
          background: Colors.candiDateBackground,
          width: { xs: '100%', sm: '90%', md: '70%' },
          padding: { xs: '20px', sm: '30px 50px', md: '30px 150px' },
          borderRadius: '16px',
          boxShadow: '0 0 1px 1px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: { xs: '16px', sm: '0' },
        }}
      >
        {/* Headings Section */}
        <Box
          sx={{
            textAlign: { xs: 'center', sm: 'left' },
          }}
        >
          <Typography
            variant='h4'
            sx={{
              fontWeight: 400,
              color: Colors.headingColor,
            }}
          >
            Easily Track & Manage Employee Hours
          </Typography>
          <Typography
            variant='subtitle1'
            sx={{
              paddingTop: '10px',
              color: Colors.grey,
            }}
          >
            Always know exactly who’s on time, late, and absent
          </Typography>
        </Box>

        {/* Button Section */}
        <ButtonComponents
          text="Let's go"
          color={Colors.white}
          background={`linear-gradient(to right, ${Colors.vivid_mulberry}, ${Colors.slate_blue})`}
          width='190px'
        />
      </Card>
    </Box>
  );
}

export default TrackCard;
