import { Box, Typography } from '@mui/material';
import React from 'react';
import employeeWork from '../../assets/empolyeeChat.avif';
import { Colors } from '../../theme/Color';

function EmployeeWork() {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: {
            xs: '80vh',
            md: '100vh',
          },
          padding: { xs: '0px', sm: '0px', md: '40px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: { xs: '30px', md: '40px' },
            maxWidth: { xs: '100%', lg: '1240px' },
            width: '100%',
          }}
        >
          {/* Image Section */}
          <Box
            sx={{
              width: { xs: '80%', md: '60%' },
              maxWidth: { xs: '660px', md: '800px' },
            }}
          >
            <img
              src={employeeWork}
              alt='wobbling'
              draggable={false}
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                borderRadius: 8,
              }}
            />
          </Box>

          {/* Text Section */}
          <Box
            sx={{
              width: { xs: '80%', md: '50%' },
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                color: Colors.headingColor,
                fontSize: {
                  xs: '18px',
                  sm: '18px',
                  md: '55px',
                  lg: '55px',
                  xl: '55px',
                },
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                  md: '60px',
                  lg: '60px',
                  xl: '60px',
                },
                marginBottom: '16px',
              }}
            >
              Record precise <br /> employee work <br /> hours your way
            </Typography>
            <Typography
              sx={{
                color: Colors.grey,
                fontSize: { xs: '14px', md: '28px' },
                lineHeight: { xs: '18px', md: '60px' },
              }}
            >
              Time clock app
            </Typography>
            <Typography
              sx={{
                color: Colors.grey,
                fontSize: { xs: '14px', md: '16px' },
                lineHeight: { xs: '18px', md: '20px' },
              }}
            >
              Have your team clock in/out via the mobile app or on-site kiosk
              app
            </Typography>
            <Typography
              sx={{
                color: Colors.grey,
                fontSize: { xs: '14px', md: '28px' },
                lineHeight: { xs: '18px', md: '60px' },
              }}
            >
              Digital timesheets
            </Typography>
            <Typography
              sx={{
                color: Colors.grey,
                fontSize: { xs: '14px', md: '16px' },
                lineHeight: { xs: '18px', md: '20px' },
              }}
            >
              Fill work hours manually without hassle or mistakes
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default EmployeeWork;
