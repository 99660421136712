import React from 'react';
import { Box, Typography } from '@mui/material';
import { Colors } from '../theme/Color';
import Recruitment from '../assets/recruitment.svg';
import ButtonComponents from './ButtonComponents';
import { useNavigate } from 'react-router-dom';

const RecruitmentSoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Desktop */}
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          background: Colors.candiDateBackground,
          clipPath: 'polygon(0 0, 100% 0, 100% 85%, 0% 100%)',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            maxWidth: { md: '800px', lg: '1200px', xl: '1240px' },
            height: { md: '80vh', lg: '80vh', xl: '80vh' }, // Reduced height
          }}
        >
          <Box
            sx={{
              width: '50%',
              maxWidth: '560px',
              paddingBottom: '10%',
            }}
          >
            <Typography
              sx={{
                color: Colors.headingColor,
                fontWeight: 900,
                fontSize: { md: '30px', lg: '40px', xl: '40px' },
                lineHeight: { md: '45px', lg: '64.17px', xl: '64.17px' },
              }}
            >
              Who uses Schedule App?
            </Typography>
            <Typography
              sx={{
                color: Colors.grey,
                paddingTop: '16px',
                fontWeight: 500,
                fontSize: { md: '18px', lg: '18px', xl: '18px' },
                lineHeight: { md: '20px', lg: '21.78px', xl: '21.78px' },
              }}
            >
              Thousands of businesses use Schedule App to track and manage
              millions of employees’ time for payroll, invoicing, and
              scheduling. Schedule App is the best automated time tracking
              software for businesses of all sizes.
            </Typography>
            <Box sx={{ paddingTop: '40px' }}>
              <ButtonComponents
                text='Get Your Demo'
                color={Colors.white}
                background={`linear-gradient(to right, ${Colors.vivid_mulberry}, ${Colors.slate_blue})`}
                width='191px'
                onclick={() => navigate('book-demo')}
              />
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '10%',
            left: '53%',
            width: '90vw',
            height: '100%',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '45vw',
              top: '50%',
              left: '30%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <img
              style={{ width: '80%', height: '100%' }}
              src={Recruitment}
              alt='RecruitmentSoftware'
              draggable={false}
            />
          </Box>
        </Box>
      </Box>

      {/* Mobile */}
      <Box
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'none',
            lg: 'none',
            xl: 'none',
          },
          background: Colors.candiDateBackground,
          clipPath: 'polygon(0 0, 100% 0, 100% 93%, 0% 100%)',
          height: '70vh', // Reduced height
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            maxWidth: { xs: '400px', sm: '700px' },
            margin: 'auto',
          }}
        >
          <Box
            sx={{
              textAlign: 'center',
              paddingBottom: '2%',
            }}
          >
            <Typography
              sx={{
                color: Colors.headingColor,
                fontWeight: 700,
                fontSize: { xs: '22px' },
                lineHeight: { xs: '35px' },
                paddingTop: '20px',
              }}
            >
              Who uses Schedule App?
            </Typography>
            <Typography
              sx={{
                color: Colors.grey,
                paddingTop: '8px', // Reduced padding
                fontWeight: 400,
                fontSize: { xs: '12px', sm: '14px' },
                lineHeight: { xs: '20px' },
              }}
            >
              Thousands of businesses use Schedule App to track and manage
              millions of employees’ time for payroll, invoicing, and
              scheduling. Schedule App is the best automated time tracking
              software for businesses of all sizes.
            </Typography>
            <Box sx={{ paddingTop: '30px' }}>
              <ButtonComponents
                text='Get Your Demo'
                color={Colors.white}
                background={`linear-gradient(to right, ${Colors.vivid_mulberry}, ${Colors.slate_blue})`}
                width='191px'
                onclick={() => navigate('book-demo')}
              />
            </Box>
          </Box>
          <Box
            sx={{
              height: { xs: '40vh', sm: '50vh' }, // Reduced height
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={Recruitment}
              style={{ width: '80%', height: '100%', padding: '10px' }}
              draggable={false}
              alt='Recruitment'
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RecruitmentSoftware;
