export const Terms = () => {
  return (
    <div style={{ width: '60%', margin: 'auto', paddingTop: '100px' }}>
      <h1>Terms and Conditions</h1>
      <p>
        <strong>Effective Date:</strong> 2024/10/01
      </p>
      <p>
        <strong>Last Updated:</strong> 2024/10/01
      </p>
      <p>
        Welcome to Velocity Manager! These Terms and Conditions ("Terms") govern
        your access to and use of our scheduling application ("App"), website,
        and related services (collectively, the "Services"). By accessing or
        using the Services, you agree to these Terms. If you do not agree,
        please do not use the Services.
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By registering for an account, accessing, or using the Services, you
        confirm that you:
      </p>
      <ul>
        <li>
          Are at least 18 years old or have the legal capacity to enter into
          this agreement.
        </li>
        <li>
          Have read, understood, and agreed to these Terms and our Privacy
          Policy.
        </li>
      </ul>

      <h2>2. Account Registration and Responsibilities</h2>
      <h3>a. Account Creation</h3>
      <p>
        To use the Services, you may need to create an account by providing
        accurate, complete, and up-to-date information. You are responsible for
        maintaining the confidentiality of your login credentials.
      </p>

      <h3>b. Account Usage</h3>
      <ul>
        <li>You agree not to share your account credentials with others.</li>
        <li>
          You are responsible for all activities conducted through your account.
        </li>
      </ul>

      <h3>c. Account Termination</h3>
      <p>
        We reserve the right to suspend or terminate accounts for violations of
        these Terms or unauthorized use of the Services.
      </p>

      <h2>3. Services Provided</h2>
      <p>
        Velocity Manager offers scheduling, time tracking, invoicing, payroll
        management, notifications, and other features for businesses and
        individuals. These Services are provided on an "as-is" and
        "as-available" basis.
      </p>
      <p>
        We may modify, suspend, or discontinue any part of the Services at any
        time without notice.
      </p>

      <h2>4. User Responsibilities</h2>
      <h3>a. Appropriate Use</h3>
      <p>You agree to:</p>
      <ul>
        <li>Use the Services only for lawful purposes.</li>
        <li>
          Provide accurate information in your account and while using the
          Services.
        </li>
      </ul>
      <p>You agree not to:</p>
      <ul>
        <li>
          Use the Services to engage in illegal activities, harassment, or
          fraud.
        </li>
        <li>Interfere with or disrupt the functionality of the Services.</li>
        <li>
          Attempt to access restricted areas or reverse-engineer any part of the
          Services.
        </li>
      </ul>

      <h3>b. Data Responsibility</h3>
      <p>
        If you input employee or client data into the App, you represent that
        you have the legal right to collect and process this data. You are
        solely responsible for compliance with applicable data protection laws.
      </p>

      <h2>5. Payment and Subscription Terms</h2>
      <h3>a. Fees</h3>
      <p>
        Certain features of the Services may require payment of fees. Fees are
        clearly listed during the subscription or transaction process.
      </p>

      <h3>b. Billing and Renewal</h3>
      <ul>
        <li>
          Payments are processed on a subscription basis (monthly or annually).
        </li>
        <li>
          Subscriptions automatically renew unless canceled before the renewal
          date.
        </li>
      </ul>

      <h3>c. Refund Policy</h3>
      <p>
        Refunds, if applicable, are subject to our{' '}
        <a href='#!'>Refund Policy</a>.
      </p>

      <h2>6. Intellectual Property</h2>
      <p>
        All content, features, and functionality of the Services, including
        trademarks, logos, and software, are the exclusive property of Velocity
        Manager or its licensors.
      </p>
      <p>
        You may not reproduce, distribute, modify, or create derivative works of
        any part of the Services without prior written permission.
      </p>

      <h2>7. Third-Party Services and Links</h2>
      <p>
        The Services may integrate with or link to third-party websites or
        services. We are not responsible for the practices, policies, or content
        of these third-party services.
      </p>
      <p>
        Your interactions with third-party services are governed by their
        respective terms and conditions.
      </p>

      <h2>8. Limitation of Liability</h2>
      <p>To the fullest extent permitted by law:</p>
      <ul>
        <li>
          Velocity Manager and its affiliates are not liable for indirect,
          incidental, or consequential damages arising from your use of the
          Services.
        </li>
        <li>
          Our total liability for any claims is limited to the amount you paid
          for the Services in the preceding 12 months.
        </li>
      </ul>

      <h2>9. Disclaimer of Warranties</h2>
      <p>
        The Services are provided "as-is" and "as-available." We make no
        warranties or representations about the reliability, accuracy, or
        availability of the Services.
      </p>

      <h2>10. Indemnification</h2>
      <p>
        You agree to indemnify and hold harmless Velocity Manager, its
        affiliates, and employees from any claims, damages, or expenses arising
        from your use of the Services, violation of these Terms, or infringement
        of any third-party rights.
      </p>

      <h2>11. Termination</h2>
      <h3>a. By You</h3>
      <p>
        You may stop using the Services at any time. To cancel a subscription,
        contact <a href='mailto:admin@velocitymgr.com'>admin@velocitymgr.com</a>{' '}
        or use the cancellation feature in the App.
      </p>

      <h3>b. By Us</h3>
      <p>
        We may suspend or terminate your account without notice for violations
        of these Terms or other reasons, including inactivity or failure to pay
        fees.
      </p>

      <h2>12. Governing Law</h2>
      <p>
        These Terms are governed by the laws of Canada. Any disputes arising
        from these Terms or the Services shall be resolved in the courts of
        Canada.
      </p>

      <h2>13. Amendments to Terms</h2>
      <p>
        We may update these Terms periodically. Changes will be posted with an
        updated "Effective Date." Your continued use of the Services after
        changes take effect constitutes acceptance of the revised Terms.
      </p>

      <h2>14. Contact Information</h2>
      <p>
        If you have any questions or concerns about these Terms, please contact
        us:
      </p>
      <ul>
        <li>
          <strong>Email:</strong>{' '}
          <a href='mailto:admin@velocitymgr.com'>admin@velocitymgr.com</a>
        </li>
        <li>
          <strong>Phone:</strong> +14385359593
        </li>
        <li>
          <strong>Mailing Address:</strong> 208 Pisces Terr, Gloucester, ON, K1T
          0S8
        </li>
      </ul>

      <p>
        <strong>
          By using Velocity Manager, you acknowledge that you have read,
          understood, and agree to these Terms and Conditions.
        </strong>
      </p>
    </div>
  )
}
